import React from 'react'
import Page_ from '../pagesComponents/Error404'
import { withMainLayout } from '../pagesComponents/MainApp'

function Page() {
  return <Page_ />
}

Page.getLayout = withMainLayout

export default Page
