import React from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'

import { Container } from 'components/MarkupHelpers'
import PageTitleBs from 'reason/common/PageTitle.bs'
import { mediaBreakpointUp } from 'styles/media'
import { fontWeight } from 'uikit/styles/theme'
import { Link } from 'reason/next/Link'
import LinkButton from 'uikit/atoms/Buttons/LinkButton'
import { useRouter } from 'next/router'

const StyledLogo = styled.h2`
  font-size: 100px;
  font-weight: ${fontWeight.bold};
  line-height: 0.8;
  text-align: center;
  margin: 0 0 30px 0;
  color: ${props => rgba(props.theme.colors.gray, 0.5)};
  ${mediaBreakpointUp('sm')`
    font-size: 200px;
    margin: 30px 0 50px 0;
  `};
  ${mediaBreakpointUp('md')`
    font-size: 230px;
  `};
  ${mediaBreakpointUp('lg')`
    font-size: 320px;
  `};
`

const StyledTitle = styled.h3`
  color: ${props => props.theme.colors.tuatara};
  line-height: 1.25;
  font-size: 24px;
  text-align: center;
  margin: 0 0 40px 0;
  font-weight: ${fontWeight.regular};
  ${mediaBreakpointUp('sm')`
    font-size: 32px;
  `};
  ${mediaBreakpointUp('md')`
    font-size: 34px;
  `};
  ${mediaBreakpointUp('lg')`
    font-size: 44px;
  `};
`

const StyledSubtitle = styled.div`
  font-size: 18px;
  line-height: 1.25;
  color: ${props => props.theme.colors.tuatara};
  text-align: center;
  a {
    border-style: solid;
    border-color: ${props => props.theme.colors.stTropaz};
    border-width: 0 0 1px 0;
    text-decoration: none;
    &:hover {
      border-width: 0;
    }
  }
  a,
  button {
    font-size: inherit;
    color: ${props => props.theme.colors.stTropaz};
    display: inline-block;
  }
  ${mediaBreakpointUp('sm')`
    font-size: 22px;
  `};
  ${mediaBreakpointUp('md')`
    font-size: 24px;
  `};
  ${mediaBreakpointUp('lg')`
    font-size: 26px;
  `};
`

const Error404 = () => {
  const router = useRouter()
  return (
    <section>
      <PageTitleBs title="404" />
      <Container>
        <StyledLogo>404</StyledLogo>
        <StyledTitle>Sorry, we can't find the page you're looking for. </StyledTitle>
        <StyledSubtitle>
          You could{' '}
          <LinkButton
            onClick={e => {
              e.preventDefault()
              router.back()
            }}
          >
            go back
          </LinkButton>{' '}
          to where you were or <Link href="/">return to our homepage</Link>.
        </StyledSubtitle>
      </Container>
    </section>
  )
}

export default Error404
